@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&family=Red+Hat+Text:ital,wght@0,400;0,500;1,400&display=swap');


body {
  margin: 0;
  font-family: 'Red Hat Text',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background-color: #fafafa;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-repeat: repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@keyframes animateInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 2%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animateInUp {
  animation-name: animateInUp;
}

.fade-enter {
  opacity: 0.00;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.tooltip-blur {
  backdrop-filter: blur(4px);
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 1%;
}

.ant-btn-icon-only.ant-btn-lg {
  width: 64px;
  height: 64px;
}

.ant-btn-icon-only.ant-btn-lg > span {
    font-size: 28px;
}
